import * as React from "react";
import Videos from '../../data/videos.json';

const Video = () => {
    return (
        <section className="video basic-block" id="video">
        <div className="container">
            <header className="video_header basic-header mb-5 pb-5">
                <span className="basic-line">
                    <span className="basic-title my-3">Video</span>
                </span>
            </header>
            <div className="video-box">
                <div className="video_album row">
                    {Videos.youtube.map(vid => (
                        <div className="video-player col col-12 col-sm-12 col-md-6 mb-5">
                        <iframe width="100%" height="315" src={vid.url} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        )
                    )}
                </div>
            </div>
            <a className="btn btn-outline-secondary button-more" href="https://www.youtube.com/channel/UCSWr-6xyPkQJahTF7Wa7-Dw">More On Youtube</a>
        </div>
    </section>
    )
}

export default Video;